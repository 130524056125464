import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import Header from './sections/Header/_index'
import Links from './sections/Links/_index'
import HelpCenter from './helpCenter/_index'

import { Wrapper } from './style'

import pageContext from './pageContext.json'

const VolteParaInter = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Header />
        <Links />
        <HelpCenter />
      </Layout>
    </Wrapper>
  )
}

export default VolteParaInter
